import request from '@/utils/request'


// 查询用户黑名单-拉黑列表
export function listBlock(query) {
  return request({
    url: '/user/user-block/list',
    method: 'get',
    params: query
  })
}

// 查询用户黑名单-拉黑分页
export function pageBlock(query) {
  return request({
    url: '/user/user-block/page',
    method: 'get',
    params: query
  })
}

// 查询用户黑名单-拉黑详细
export function getBlock(data) {
  return request({
    url: '/user/user-block/detail',
    method: 'get',
    params: data
  })
}

// 新增用户黑名单-拉黑
export function addBlock(data) {
  return request({
    url: '/user/user-block/add',
    method: 'post',
    data: data
  })
}

// 修改用户黑名单-拉黑
export function updateBlock(data) {
  return request({
    url: '/user/user-block/edit',
    method: 'post',
    data: data
  })
}

// 删除用户黑名单-拉黑
export function delBlock(data) {
  return request({
    url: '/user/user-block/delete',
    method: 'post',
    data: data
  })
}
